import React from 'react';
import styles from '../Login/Login.module.css';


const Home = () => {
  const handleAuth = () => {
    const clientId = process.env.REACT_APP_EBAY_CLIENT_ID;
    const redirectUri = 'https://ecwid.codupcloud.com/callback';
    /* const scope = [
      'https://api.ebay.com/oauth/api_scope',
      'https://api.ebay.com/oauth/api_scope/buy.order.readonly',
      'https://api.ebay.com/oauth/api_scope/buy.guest.order',
      'https://api.ebay.com/oauth/api_scope/sell.marketplace.insights.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.catalog.readonly',
      'https://api.ebay.com/oauth/api_scope/buy.shopping.cart',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.email.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.phone.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.address.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.name.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.status.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.item.draft',
      'https://api.ebay.com/oauth/api_scope/sell.item',
    ].join(' '); */

    const scope = [
      'https://api.ebay.com/oauth/api_scope',
      'https://api.ebay.com/oauth/api_scope/sell.marketing.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.marketing',
      'https://api.ebay.com/oauth/api_scope/sell.inventory.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.inventory',
      'https://api.ebay.com/oauth/api_scope/sell.account.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.account',
      'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
      'https://api.ebay.com/oauth/api_scope/sell.analytics.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.finances',
      'https://api.ebay.com/oauth/api_scope/sell.payment.dispute',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.reputation',
      'https://api.ebay.com/oauth/api_scope/sell.reputation.readonly',
      'https://api.ebay.com/oauth/api_scope/commerce.notification.subscription',
      'https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.stores',
      'https://api.ebay.com/oauth/api_scope/sell.stores.readonly',
    ].join(' ');


    //for sandbox url will be https://auth.sandbox.ebay.com/oauth2/authorize?client_id=${clientId}
    const ebayAuthUrl = `https://auth.ebay.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
    window.location.href = ebayAuthUrl;
  };


  return (
    <div className={styles.container}>
      {<div className={styles.form}>
        <h2 className={styles.mainHeadings}>eBay SSO Login</h2>
        <p>Your session with eBay has expired. Please login to continue.</p>
        <button type="submit" className={styles.loginBtn} onClick={handleAuth}>Login With Ebay</button>
      </div>}
    </div>
  );
};

export default Home;
